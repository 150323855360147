.header {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 6.4rem;
  background: $white;
  box-shadow: $box-shadow;
  z-index: 100;
}

.header-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 6.4rem;
  position: relative;
  padding: 1rem 0;

  .logo {
    display: flex;
    min-width: 13rem;
    width: 13rem;
    height: 4.6rem;
    flex-direction: row;
    align-items:center;
    justify-content:center;
    align-content:center;
    margin-right: 2rem;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .phones-list {
    display: block;
    margin: auto 0 auto auto;
    padding-left: 1rem;

    li {
      display: block;
      width: 100%;
      margin: 0 0 .5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
    a.phone-link {
      display: block;
      padding-left: 3rem;
      position: relative;
      color: $text-color;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-decoration: none;
      transition: color $anim ease;
      white-space:nowrap;

      &:hover {
        color: $color-main;
      }

      .phone-link-icon {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 2rem;
        height: 2rem;

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
        }
      }
    }
    @media screen and (max-width: $table-screen) {
      padding-right: 7rem;
    }
  }//phones-list
}//header-inner

.header-inside {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding-right: 3rem;

  @media screen and (max-width: $table-screen) {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    background: $white;
    margin-left: -1.5rem;
    padding: 1rem 0;
    max-height:calc(100vh - 6.4rem);
    overflow-y:auto;
    border-top: .1rem solid $color-main;
    border-bottom: .1rem solid $color-main;
  }
  @media screen and (min-width:($table-screen + 1)) {
    display: flex !important;
  }
}
.main-menu-wrap {
  display: block;
}

.main-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: -1rem 0 0;
  padding: 0;

  li {
    display: block;
  }
  a {
    display: block;
    height: 6.4rem;
    text-align: center;
    padding: 1rem 2rem;
    position: relative;
    font-size:1.6rem ;
    line-height: 4.4rem;
    font-weight: 500;
    color: $text-color;
    transition:color $anim ease;
    white-space:nowrap;

    &:hover {
      color: $color-main;
    }

    &:after {
      content:'';
      display: block;
      position: absolute;
      bottom: 0;
      width: 0;
      left: 50%;
      transform:translate(-50%,0);
      height: .4rem;
      background: $color-main;
      transition:all $anim ease;
    }


    &.active {
      &:after {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $table-screen) {
    display: block;
    margin: 0;
    padding: 2rem 1.5rem;


    li {
      margin-bottom: 1rem;
    }

    a {
      font-size: 1.8rem;
      line-height: 2.1rem;
      padding: 1rem 2rem;
      height: 5rem;

      &.active {
        &:after {
          width: 40%;
        }
      }
    }
  }
}

.head-account {
  display: flex;
  flex-direction: row;
  align-items:center;
  height: 6.4rem;
  margin: -1rem 0 0 auto;
  padding: 0 0 0 2rem;

  .acc-btn-list {
    display: flex;
    flex-direction: row;
    align-items:center;

    li {
      display: block;
      list-style:none;
      padding: 0 2rem;
    }
  }
  .header-link {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    color: $text-color;
    text-decoration: underline;

    &:hover {
      text-decoration:none;
    }
  }
  .profile-head {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .profile-head-link {
    display: inline-block;
    padding-left: 3rem;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-main;
    text-decoration: underline;
    position: relative;
    margin-right: 1rem;
    font-weight: 500;

    &:hover {
      text-decoration: none;
    }

    .prof-icon {
      display: block;
      width: 2rem;
      height: 2rem;
      left: 0;
      top: 0;
      position: absolute;

      .icon{
        fill:$color-main;
      }
    }
  }
  .profile-head-logout {
    display: block;
    width: 2rem;
    height: 2rem;

    &:hover {
      .icon {
        fill:$black;
      }
    }

    .icon {
      fill:$gray-color;
    }

  }
  @media screen and (max-width: $table-screen) {
    padding: 2rem 1.5rem 4rem;
    height: auto;
    justify-content:center;

    .acc-btn-list {
      margin: 0 auto;
    }
  }
}//head-account


.menu-toggle {
  display: none;
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 4rem;
  background: none;

  @media screen and (max-width: $table-screen) {
    display: block;
  }

  &:after {
    content:'';
    display: block;
    position: absolute;
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }

  .line {
    display: block;
    position: absolute;
    top: 50%;
    height: .4rem;
    width: 4rem;
    background: $color-main;
    margin-top: -.2rem;
    border-radius:.2rem;
    transition:all $anim ease;
    left: 50%;
    margin-left: -2rem;

    &.line-top {
      margin-top: -1.2rem;
    }
    &.line-bot {
      margin-top: .7rem;
    }
  }

  &.active {
    .line {
      &.line-mid {
        opacity: 0;
        width: 0;
        margin-left: 0;
        background: $black;
      }
      &.line-top {
        margin-top: -0.2rem;
        transform:rotate(135deg);
        background: $black;
      }
      &.line-bot {
        margin-top: -0.2rem;
        transform:rotate(-135deg);
        background: $black;
      }
    }
  }
}//menu-toggle
