.section-schedule {
  display: block;
  width: 100%;
  background: $white;
  padding: 10rem 0;
  margin: 0;

  @media screen and (max-width:$table-screen) {
    padding: 5rem 0;
  }
}

.schedule-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 -2rem 5rem;

  .schedule-part {
    display: block;
    width: 50%;
    padding: 0 2rem;
  }
  .schedule-part__direction {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content:center;
    align-items:center;
    margin: 0 0 4rem;
    color: $color-main;
    font-weight: 500;
    font-size: 2.1rem;
    line-height: 2.5rem;

    .d_from {
      padding-right: 1rem;
    }
    .d_to {
      padding-left: 1rem;
    }
    .d_separator {
      display: block;
      width: 3.2rem;
      height: 3.2rem;

      .icon {
        fill:$color-main;
      }
    }

  }
  .t__schedule {
    display: block;
    margin: 0 auto;
    width: 50rem;
  }
  .t__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content:flex-start;
    border-bottom: .1rem solid $grey-border;
    padding: 1.6rem 0;
  }
  .t__head {
    .t__col {
      font-weight: bold;

      &.t__col-center {
        color: $text-color;
      }
    }
  }
  .t__col {
    display: block;
    width: 100%;
    font-size: 1.8rem;
    line-height: 1;
    color: $text-color;
    font-weight: 500;

    &:last-child {
      text-align: right;
    }

    &.t__col-center {
      color: $gray-text;
      padding: 0 .5rem;
      text-align: center;
    }
  }
  @media screen and (max-width: $table-screen) {
    flex-wrap: wrap;
    .schedule-part {
      width: 100%;
      padding: 0 2rem;

    }
    .schedule-part + .schedule-part {
      padding-top: 6rem;
    }
    .t__schedule {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width:46rem;
    }
    .schedule-part__direction {
      margin-bottom: 1rem;
    }
  }
}
.schedule__text {
  display: block;
  max-width:120rem;
  margin: 0 auto;

  font-size: 1.8rem;
  line-height: 2.8rem;
  color: $text-color;
  & > * {
    margin: 0 0 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
