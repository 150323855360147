.section-news {
  background: $base-bg;
}
.news-block {
  display: block;
  margin: 0 -2rem;
  padding: 0;

  .news-one {
    display: block;
    width: 33.3333%;
    padding: 0 2rem 4rem;
    margin: 0;

    &.news-one-sizer {
      width: 33.3333%;
      padding: 0;
      margin: 0;
    }
  }
  .news-one__inner {
    display: block;
    width: 100%;
    border-radius:$border-rad;
    box-shadow:$box-shadow;
  }
  .news-one__img {
    display: block;
    width: 100%;
    padding: 0 0 56%;
    border-radius:$border-rad $border-rad 0 0;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .news-one__details {
    display: block;
    padding: 2.5rem;
    width: 100%;
  }
  .news-one__text {
    display: block;
    width: 100%;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $text-color;
    margin: 0 0 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .news-one__date {
    display: block;
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.8rem;
    text-align: right;
    color: $gray-text;
  }
  @media screen and (max-width: $table-screen) {

    .news-one {
      width: 50%;

      &.news-one-sizer {
        width: 50%;
      }
    }
  }//$table-screen
  @media screen and (max-width: $mobile-screen) {

    .news-one {
      width: 100%;

      &.news-one-sizer {
        width: 100%;
      }
    }
  }//$table-screen
}//news-block
