.friend-one {
  display: block;
  width: 54rem;
  margin: 0 auto 8rem;

  .friend-one__inner {
    display: block;
    width: 100%;
    text-align: right;
  }
  .friend-one__img {
    display: block;
    width: 100%;
    margin: 0 0 2.2rem;
  }
  .friend-one__link {
    display: inline-block;
    position: relative;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: $color-main;
    padding-right: 4.2rem;
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  .friend-one__link-icon {
    display: block;
    width: 2.8rem;
    height: 2.8rem;
    position: absolute;
    top: 0;
    right: 0;

    .icon {
      fill:$color-main
    }
  }
  @media screen and (max-width: $table-screen) {
    display: block;
    width: 100%;
    margin: 0 auto 4rem;
    max-width:44rem;

    .friend-one__img {
      margin: 0 0 1rem;
    }

    .friend-one__link {
      font-size: 1.8rem;
      line-height: 2.4rem;
      padding-right: 3.6rem;
    }
    .friend-one__link-icon {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}
