footer {
  display: block;
  width: 100%;
  box-shadow:$box-shadow;
  background: $white;

  .footer__inner {
    display: block;
    padding: 2.5rem 0 5rem;
  }
  .footer-info,
  .footer-copyright {
    display: block;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $text-color;
    margin: 0 0 2rem;
  }
  .footer-info {
    margin: 0;
  }
}

