@import "./normalize";
@import "vars";
@import "./mixins";
@import "fonts";
@import "./sprite_generated";
@import "../../libs/slick-carousel/slick/slick.scss";

*,
*::before,
*::after {
    box-sizing: border-box;
}

:active,
:focus,
:hover {
    outline: 0;
}


html,
body {
    height: 100%;
    padding: 0;

}

html {
    font-size:10px;
    line-height:1.2;
    overflow-y:scroll;

    @media screen and (max-width:1500px) {
        font-size: 0.65vw;
    }
    @media screen and (max-width:$table-screen) {
        font-size: 1.25vw;
    }
    @media screen and (max-width:$mobile-screen) {
        font-size: 2vw;
    }

}

body {
    display:block;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    background-color: $base-bg;
    color: $base-color;
    font-family: $font-default;
    font-weight: normal;
    font-size:1.4rem;
}

.layout {
    position: relative;
    min-height: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

a {
    text-decoration:none;
    color:inherit;
}
a {
    cursor:pointer;
}
p {
    margin: 15px 0;
}

p:empty {
    display: none;
}

input {
    -webkit-appearance: none;
    border-radius: 0;
}

.svg-store {
    display: none;
    height: 0;
    overflow: hidden;
}
img {
    display: block;
    max-width:100%;
}

.container {
    width: 100%;
    max-width: 140rem + $cp * 2;
    padding:0 $cp;
    margin-left: auto;
    margin-right: auto;
    position:relative;

    @media screen and (max-width: $table-screen) {
        max-width:100%;
        padding: 0 1.5rem;
    }
}

.icon {
    display:block;
    width:100%;
    height:100%;
}

section {
    padding: 10rem 0;

    @media screen and (max-width: $table-screen) {
        padding: 5rem 0;
    }
}
.text-center {
  text-align: center;
}

.n-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -2rem;

    .n-col {
        display: block;
        width: 50%;
        margin: 0 ;
        padding: 0 4rem;
    }
    @media screen and (max-width: $table-screen) {
       .n-col {
           width: 100%;
           padding:0 2rem;
       }
    }
}
.space-separator {
    display: block;
    height: 3rem;
    width: 100%;
    background: none;
    opacity: 0;
}
.text-block {
    display: block;
    width: 100%;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $text-color;

    & > * {
        margin: 0 0 2.5rem;
    }

    ul {
        padding: 0;
        margin: 0 0 2.5rem;
        li {
            position: relative;
            padding-left: 3.2rem;
            list-style:none;
            margin: 0 0 1rem;

            &:before {
                content:'';
                display: block;
                position: absolute;
                height: 1rem;
                width: 2.2rem;
                border-bottom: .2rem solid $color-main;
                border-left: .2rem solid $color-main;
                left: 0;
                top: .3rem;
                transform:rotate(-45deg);
            }
        }
    }
}
