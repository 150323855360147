.section-main {
  display: block;
  width: 100%;
  background-position: 50%;
  background-color: $white;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 0;
}

.main-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height:100vh;
  margin: 0 -2rem;
  padding: 10rem 0 5rem;

  .main-part {
    display: block;
    width: 50%;
    padding: 0 2rem;
  }

  .main-part-left {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 60%;
  }
  .main-part-right {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 40%;
  }
  @media screen and (max-width: $table-screen) {
    flex-wrap: wrap;
    padding: 8rem 0 4rem;

    .main-part-right,
    .main-part-left {
      width: 100%;
      padding: 0 1.5rem;
    }
    .main-part-right {
      padding-top: 2rem;
    }
  }
}

.section-title {
  display: block;
  width: 100%;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: $text-color;
  margin: 0 0 6rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: $table-screen) {
    margin: 0 0 3rem;
  }
}

.info-block {
  display: block;
  width: 36rem;
  position: relative;
  margin: 4rem 0;
  min-height:12rem;
  padding: 1.5rem 2rem;

  &:before {
    content:'';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    box-shadow:$box-shadow;
    position: absolute;
    transform:skewX(-30deg);
    border-radius:$border-rad;
  }

  .info-block__title {
    display: block;
    text-align: center;
    color: $color-main;
    font-size: 2.1rem;
    line-height: 2.4rem;
    font-weight: 500;
    margin: 0 0 .5rem;
    position: relative;
    white-space:nowrap;
  }
  .info-block__data {
    display: block;
    position: relative;
  }
  &.info-block-main {
    width: 50rem;
    margin-left: auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
    .info-block__title {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }
  .info-price {
    display: block;
    text-align: center;
    font-size: 4rem;
    font-weight: bold;
    white-space:nowrap;

    span {
      font-size: 5rem;
    }
  }
  .info-direction {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;

    .info-direction__from,
    .info-direction__to {
      display: block;
      font-size: 1.8rem;
      line-height: 2.2rem;
      color: $text-color;

      span {
        display: block;
        margin: .8rem 0;
      }
    }
    .info-direction__from {
      text-align: right;
    }
    .info-direction__icon {
      display: block;
      width: 5.2rem;
      height: 5.2rem;
      margin: 0 2rem;

      .icon {
        fill:$text-color;
      }
    }
  }
  .sale-info {
    display: block;

    .sale-info__title {
      display: block;
      font-size: 1.8rem;
      line-height: 2.2rem;
      color: $text-color;
      margin: 0 0 .5rem;
      text-align: center;
      font-weight: 500;
    }
    .sale-info__text {
      display: block;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: $gray-color;
      text-align: center;
    }
  }
  @media screen and (max-width: $table-screen) {
    width: 34rem;
    margin: 1rem auto;
    min-height:10rem;
    padding: 1.0rem 1.5rem;

    .info-block__title {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
    &.info-block-main {
      width: 44rem;
      margin-left: auto;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      .info-block__title {
        font-size: 2.1rem;
        line-height: 2.4rem;
      }
    }
    .info-price {
      font-size: 3rem;

      span {
        font-size: 4rem;
      }
    }
    .sale-info {

      .sale-info__title {
        display: block;
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: $text-color;
        margin: 0 0 .5rem;
        text-align: center;
        font-weight: 500;
      }
      .sale-info__text {
        display: block;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: $gray-color;
        text-align: center;
      }
    }
  }
}//info-block

.main-online {
  display: block;
  width: 100%;
  max-width:48rem;
  margin: 0 0 0 auto;
  box-shadow:$box-shadow;
  background: $white;
  border-radius:$border-rad;
  padding: 4.5rem 6rem;

  .online-separator {
    display: block;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.8rem;
    font-style: italic;
    color: $gray-text;
    margin: 5rem 0;
  }
  .download-block {
    display: block;
    width: 100%;
  }
  .download-block__title {
    display: block;
    text-align: center;
    color: $text-color;
    font-size: 2.1rem;
    line-height: 2.4rem;
    font-weight: 500;
    margin: 0 0 3.5rem;
  }
  .download-block__list {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin: 0;
    padding: 0 2rem;

    li {
      display: block;
      list-style:none;
      max-width:50%;
    }
    img {
      display: block;
      height: 5rem;
      width: auto;
    }
  }
  @media screen and (max-width: $table-screen) {
    max-width:48rem;
    margin: auto;
    padding: 3.5rem 4rem;

    .online-separator {
      margin: 4rem 0;
    }
    .download-block__title {
      margin: 0 0 2.5rem;
    }
  }
}


.form-main {
  display: block;

  .form-group {
    width: 30rem;
    margin: 0 auto;
    position: relative;
  }
  .form-step {
    position: absolute;
    right: 100%;
    top: 2.5rem;
    bottom: 2.5rem;
    margin: 0 5rem 0 0;
    width: .1rem;
    background: $color-main;
    padding: 0;

    li {
      display: block;
      list-style:none;
    }
    .step-one {
      display: block;
      width: 1.3rem;
      height: 1.3rem;
      position: absolute;
      left: 50%;
      transform:translate(-50%,-50%);
      top: 0;

      &.done {
        &:after {
          background: $color-main;
        }
      }

      &:after {
        content:'';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius:50%;
        border:.1rem solid $color-main;
        background: $white;
        transition:background-color $anim ease;
      }

      &.step-one-2 {
        top: 25%;
      }
      &.step-one-3 {
        top: 50%;
      }
      &.step-one-4 {
        top: 75%;
      }
      &.step-one-5 {
        top: 100%;
      }
    }
  }
  @media screen and (max-width: $table-screen) {

    .form-group {
      width: 32rem;
    }
    .form-step {
      position: absolute;
      right: 100%;
      top: 2.5rem;
      bottom: 2.5rem;
      margin: 0 2.5rem 0 0;
    }
  }
}
