.btn {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: none;
  background-color: transparent;
  max-width:100%;
  text-align: center;

  &.btn-wide {
    width: 100%;
  }
}

.btn-default {
  min-width:20rem;
  height: 5rem;
  color: $white;
  font-size: 1.8rem;
  line-height: 2rem;
  padding: 1.5rem 2rem;
  font-weight: 500;
  background: $color-main;
  box-shadow:0 .2rem .2rem rgba(0,0,0,.25);
  border-radius:2.5rem;
  transition:background-color $anim ease;

  &:hover {
    background: $color-main - #111;
  }
}

.btn-cancel {
  min-width:20rem;
  height: 5rem;
  color: $gray-color;
  font-size: 1.8rem;
  line-height: 2rem;
  padding: 1.5rem 2rem;
  font-weight: 500;
  background: $white;
  box-shadow:0 .2rem .2rem rgba(0,0,0,.25);
  border-radius:2.5rem;
  transition:all $anim ease;
  position: relative;

  &:after {
    content:'';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius:inherit;
    border:.1rem solid $gray-color;
  }

  &:hover {
    background: $gray-color;
    color: $white;
  }
}
.btn-login {
  min-width:12rem;
  height: 4rem;
  color: $color-main;
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 1rem 2rem;
  font-weight: 500;
  background: $white;
  box-shadow:0 .2rem .2rem rgba(0,0,0,.25);
  border-radius:2.5rem;
  transition:all $anim ease;
  position: relative;

  &:after {
    content:'';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius:inherit;
    border:.1rem solid $color-main;
  }

  &:hover {
    color: $white;
    background: $color-main;
  }
}

.btn__wrap {
  display: block;
  width: 100%;

  &.align-right {
    text-align: right;
  }
  &.align-center {
    text-align: center;
  }
}
