.stay-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -2rem 0;

  .stay-one {
    display: block;
    width: 50%;
    padding: 0 2rem 4rem;
  }
  .stay-one__inner {
    display: block;
    width: 100%;
    box-shadow:$box-shadow;
    border-radius:$border-rad;
    background: $white;
    padding: 0;
    margin: 0;
  }

  .stay-one__head {
    display: block;
  }
  .stay-one__head {
    display: flex;

    flex-direction: row;
    align-content:center;
    align-items:center;
    justify-content:center;
    padding: 2rem 2.5rem;
    min-height:11rem;
    position: relative;
    border-radius:$border-rad $border-rad 0 0;
    transition:background-color $anim ease;

    &.active {
      background: $color-main;

      .stay-one__title {
        color: $white;
      }

      .stay-one__toggle {
        color: $white;
        &:after {
          top: .5rem;
          transform:rotate(-135deg);
          border-color:$white;

        }
        .name-more {
          display: none;
        }
        .name-less {
          display: block;
        }
      }
    }
  }
  .stay-one__title {
    display: block;
    text-align: center;
    color: $color-main;
    font-size:2.4rem;
    font-weight: 500;
    transition:color $anim ease;
  }
  .stay-one__toggle {
    display: inline-block;
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    padding-right: 2.5rem;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 500;
    color: $gray-color;

    .name-less {
      display: none;
    }

    &:after {
      content:'';
      display: block;
      position: absolute;
      width: 1.4rem;
      height: 1.4rem;
      border-bottom: .1rem solid $gray-color;
      border-right:.1rem solid $gray-color;
      right: 0;
      top: -.4rem;
      transform:rotate(45deg);
      transition:all $anim ease;
    }

  }
  .stay-one__body {
    display: none;
  }
  .stay-one__body_inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 0;
  }
  .stay-line {
    display: block;
    width: 50%;
    padding: 0 2rem;
    font-size: 1.4rem;
    line-height: 1;
  }
  .stay-line__inner {
    padding: 1rem 0;
    border-bottom: .1rem solid $grey-border;
  }
  @media screen and (max-width:$table-screen) {
    flex-wrap: wrap;
    margin: 0 -2rem 0;

    .stay-one {
      width: 100%;
      padding-bottom: 1rem;
    }
  }
}//stay-block
