.contact-single {
  display: block;
  width: 100%;
  margin: 0 0 5rem;

  &:last-child {
    margin-bottom: 0;
  }


  .cs__inner {
    display: block;
    width: 100%;
    padding: 3rem 4rem;
    border-radius:$border-rad;
    box-shadow:$box-shadow;
    background: $white;
  }
  .cs_title {
    display: block;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $text-color;
    margin: 0 0 1rem;
  }
  .cs_subtitle {
    display: block;
    font-style: italic;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: $gray-color;
    margin: 0 0 4rem;
  }
  .cs_data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -1.5rem;
  }
  .cs_data__part {
    display: block;
    width: 50%;
    padding: 0 1.5rem;

    @media screen and (max-width: $table-screen) {
      width: 100%;
      margin-top: 1rem;
    }
  }
  .cs-link {
    display: inline-block;
    font-size: 2.1rem;
    line-height: 2.8rem;
    position: relative;
    color: $black;
    font-weight: 500;
    padding-left: 3.8rem;
    transition:color $anim ease;
    white-space:nowrap;

    &:hover {
      color: $color-main;
    }
  }
  .cs-link__icon {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 2.8rem;
    height: 2.8rem;

    img {
      display: block;
      max-width:100%;
      max-height:100%;
    }
    .icon {
      fill:$color-main;
    }
  }
}//contact-single


.contacts-form {
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width:60rem;
  box-shadow:$box-shadow;
  background: $white;
  border-radius:$border-rad;
  padding: 4.5rem 6rem;

  @media screen and (max-width: $table-screen) {
    margin-top: 8rem;
    padding: 4.0rem 3rem;
  }
}

form {
  .form-line {
    display: block;
    margin: 0 0 3.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .form-label {
    display: block;
    width: 100%;
    position: relative;

    &.focused,
    &.activated {
      .label-placeholder {
        top: -1rem;
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
  }
  .label-placeholder {
    display: inline-block;
    padding: 0 0.5rem;
    top: 1.5rem;
    font-size: 1.6rem;
    line-height: 2rem;
    left: 2.0rem;
    position: absolute;
    background: $white;
    transition:all $anim ease;
    border-radius:.5rem;
  }
  .form-input {
    display: block;
    width: 100%;
    height: 5rem;
    border-radius:2.5rem;
    border:.1rem solid $color-main;
    box-shadow:0 .2rem .2rem rgba(0,0,0,.25);
    padding: 1.5rem 5rem 1.5rem 2.5rem;
    font-family: $font-default;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $text-color;
  }
  textarea.form-input {
    height: 15rem;
    resize:none;
  }
  .label-icon {
    display: block;
    position: absolute;
    width: 2.6rem;
    height: 2.6rem;
    top: 1.2rem;
    right: 2rem;

    .icon {
      fill:$color-main;
    }
  }
}

.form__title {
  display: block;
  text-align: center;
  font-size: 2.4rem;
  line-height: 1;
  color: $color-main;
  font-weight: 500;
  margin: 0 0 5rem;
}


.checkbox-label {
  display: block;
  width: 100%;
  position: relative;
  padding-left: 4rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: $text-color;
  font-weight: 500;
  padding-top: .2rem;
  cursor:pointer;

  .checkbox-icon {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    border-radius:$border-rad;
    background: $white;
    border:.1rem solid $gray-color;

    .icon {
      fill:$color-main;
      transform:translate(.5rem);
      transition:opacity $anim ease;
      opacity: 0;
    }
  }
  input[type=checkbox]:checked + .checkbox-icon {
    .icon {
      opacity: 1;
    }
  }
}//checkbox-label


.form-change-password {
  display: block;
  margin: 0 auto;
  max-width:50rem;
  padding-top: 3rem;
}
