.section-about {
  display: block;
  background: $white;
}

.slider-about {
  display: block;
  margin: 0 -2rem 5rem;

  &:before,
  &:after {
    content:'';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2rem;
    background: $white;
    z-index: 40;
  }
  &:before {
    left: 0;
  }
  &:after {
    left: auto;
    right: 0;
  }

  .slide-one {
    padding: 0 2rem;
  }
  .slide-one-img {
    display: block;
    height: 28rem;
    border-radius:$border-rad;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .slick-arrow {
    display: block;
    width: 3rem;
    height: 3rem;
    background: none;
    border:none;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    margin-top: -1.5rem;
    cursor:pointer;

    &:after {
      content:'';
      display: block;
      position: absolute;
      width: 2.2rem;
      height: 2.2rem;
      border-bottom: .2rem solid $color-main;
      border-left: .2rem solid $color-main;
      top: 50%;
      margin-top: -1.1rem;
    }
  }

  .slick-prev {
    left: -4rem;

    &:after {
      transform:rotate(45deg);
      left: 1rem;
    }
  }
  .slick-next {
    right: -4rem;
    &:after {
      transform:rotate(-135deg);
      left: auto;
      right: 1rem;
    }
  }
  @media screen and (max-width: $table-screen) {
    .slick-prev {
      left: 1.5rem;
      z-index: 5;
    }
    .slick-next {
      right: 1.5rem;
    }
  }
}
